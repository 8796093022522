import { IFaqs, IFaqsOptions } from '@/types/faqs';
import {
  ArrowDownTrayIcon,
  BellIcon,
  MapIcon,
  ShoppingCartIcon,
  UserPlusIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import React from 'react';
import { itemsAdding } from './faqs-adding';
import { itemsPlansBilling } from './faqs-plans';
import { itemsMapping } from './faqs-mapping';
import { itemsDownloads } from './faqs-download';
import { itemsManaging } from './faqs-managing';
import { itemsNotifications } from './faqs-notifications';
import { removeDuplicates } from '@/utils/remove-duplicate';

export const faqsData: IFaqs[] = [
  {
    icon: <UserPlusIcon className="w-6 h-6" />,
    text: 'Adding from Training.gov.au',
    link: '/faqs',
    items: itemsAdding,
    isOpen: false
  },
  {
    icon: <ShoppingCartIcon className="w-6 h-6" />,
    text: 'Plans & Billing',
    link: '/faqs/plans-billing',
    items: itemsPlansBilling,
    isOpen: false
  },
  {
    icon: <MapIcon className="w-6 h-6" />,
    text: 'Mapping',
    link: '/faqs/mapping',
    items: itemsMapping,
    isOpen: false
  },
  {
    icon: <ArrowDownTrayIcon className="w-6 h-6" />,
    text: 'Downloads',
    link: '/faqs/downloads',
    items: itemsDownloads,
    isOpen: false
  },
  {
    icon: <BellIcon className="w-6 h-6" />,
    text: 'Notifications',
    link: '/faqs/notifications',
    items: itemsNotifications,
    isOpen: false
  },
  {
    icon: <UsersIcon className="w-6 h-6" />,
    text: 'Managing your account',
    link: '/faqs/managing',
    items: itemsManaging,
    isOpen: false
  }
];

const convertFaqsData = faqsData.map((x) => {
  return x.items.map((y) => {
    return {
      label: y.title,
      value: y.title,
      elements: y.elements,
      link: x.link
    };
  });
});

export const faqsOptions: IFaqsOptions[] = convertFaqsData.flat();

export const options: IFaqsOptions[] = removeDuplicates(faqsOptions);
