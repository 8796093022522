import { Link } from 'gatsby';
import React from 'react';

export const itemsAdding = [
  {
    title: 'What happens to my maps when Training.gov.au updates a unit?',
    elements: (
      <div>
        <p>
          <i>
            If I update a previously added unit's details after it has been superseded, will the new unit data overwrite
            the old unit data in my previous maps?
          </i>
        </p>
        <br />
        <p>
          <u>Short answer:</u> Your maps stay attached to the outdated release of that unit{' '}
          <span className="font-bold"> unless you choose to update it.</span>
        </p>
        <br />
        <p className="font-bold">Unit releases</p>
        <p>See which release a map is attached to in the unit index. </p>
        <br />
        <p>When there is a new release of a unit, the release number (ie. 1.0, 1.1, etc.) will change.</p>
        <br />
        <p>
          When you continue working on a map that belongs to an outdated release of a unit, a notification bar will
          appear at the top of your screen to ensure you are aware that you are working with superseded data and have
          the option to update.
        </p>
        <br />
        <p>
          Once you have completed editing the map and click Save again, it's your choice to choose to update the map
          (change the release number) or keep it in the same old release.
        </p>
      </div>
    )
  },
  {
    title: 'How do I know if my data is current and updated?',
    elements: (
      <div>
        <p>
          When Training.gov.au issues an update, it typically causes a unit or course to become outdated or superseded.{' '}
        </p>
        <br />
        <p>
          Magic Map always pulls the most recent release of a Unit when you start a new sync, so you never have to worry
          about mapping superseded unit data unless you have specifically selected that option.
        </p>
        <br />
        <p>
          For your existing maps, when Training.gov.au issues an update there are a few ways in which we notify you.
        </p>
        <br />
        <p className="font-bold">1. In-app notifications & Email</p>
        <br />
        <p className="font-bold">2. At map level</p>
        <br />
        <p>A notification bar will appear at the top of your mapping screen.</p>
      </div>
    )
  }
];
