import React from 'react';
import './src/assets/css/global.scss';
import Layout from './src/components/Layout';
import { DataProvider } from './src/context/data-context';

interface Props {
  element: JSX.Element | JSX.Element[];
  props: object;
}

export const wrapPageElement: React.FC<Props> = ({ element, props }) => {
  return (
    <DataProvider value={props}>
      <Layout {...props}>{element}</Layout>
    </DataProvider>
  );
};
