exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-downloads-tsx": () => import("./../../../src/pages/faqs/downloads.tsx" /* webpackChunkName: "component---src-pages-faqs-downloads-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-faqs-managing-tsx": () => import("./../../../src/pages/faqs/managing.tsx" /* webpackChunkName: "component---src-pages-faqs-managing-tsx" */),
  "component---src-pages-faqs-mapping-tsx": () => import("./../../../src/pages/faqs/mapping.tsx" /* webpackChunkName: "component---src-pages-faqs-mapping-tsx" */),
  "component---src-pages-faqs-notifications-tsx": () => import("./../../../src/pages/faqs/notifications.tsx" /* webpackChunkName: "component---src-pages-faqs-notifications-tsx" */),
  "component---src-pages-faqs-plans-billing-tsx": () => import("./../../../src/pages/faqs/plans-billing.tsx" /* webpackChunkName: "component---src-pages-faqs-plans-billing-tsx" */),
  "component---src-pages-features-data-storage-tsx": () => import("./../../../src/pages/features/data-storage.tsx" /* webpackChunkName: "component---src-pages-features-data-storage-tsx" */),
  "component---src-pages-features-excellent-support-tsx": () => import("./../../../src/pages/features/excellent-support.tsx" /* webpackChunkName: "component---src-pages-features-excellent-support-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-features-more-manageable-tsx": () => import("./../../../src/pages/features/more-manageable.tsx" /* webpackChunkName: "component---src-pages-features-more-manageable-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

