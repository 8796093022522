import { IFaqsOptions } from '@/types/faqs';

export const removeDuplicates = (array: IFaqsOptions[] = []) => {
  const uniqueArray: IFaqsOptions[] = [];

  array.forEach((obj) => {
    const isDuplicate = uniqueArray.some((uniqueObj) => uniqueObj.label === obj.label);

    if (!isDuplicate) {
      uniqueArray.push(obj);
    }
  });

  return uniqueArray;
};
