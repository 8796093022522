import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const itemsMapping = [
  {
    title: 'What happens to my maps when Training.gov.au updates a unit?',
    elements: (
      <div>
        <p>
          <i>
            If I update a previously added unit's details after it has been superseded, will the new unit data overwrite
            the old unit data in my previous maps?
          </i>
        </p>
        <br />
        <p>
          <u>Short answer:</u> Your maps stay attached to the outdated release of that unit{' '}
          <span className="font-bold"> unless you choose to update it.</span>
        </p>
        <br />
        <p className="font-bold">Unit releases</p>
        <p>See which release a map is attached to in the unit index. </p>
        <br />
        <p>When there is a new release of a unit, the release number (ie. 1.0, 1.1, etc.) will change.</p>
        <br />
        <p>
          When you continue working on a map that belongs to an outdated release of a unit, a notification bar will
          appear at the top of your screen to ensure you are aware that you are working with superseded data and have
          the option to update.
        </p>
        <br />
        <p>
          Once you have completed editing the map and click Save again, it's your choice to choose to update the map
          (change the release number) or keep it in the same old release.
        </p>
      </div>
    )
  },
  {
    title: 'What file types are my maps available for download in?',
    elements: (
      <div>
        <p>
          After completing your unit map within Magic Map, you may then download it in any or all of the following
          formats:
        </p>
        <br />
        <ul className="list-disc pl-6">
          <li>PDF</li>
          <li>Word</li>
          <li>Excel</li>
        </ul>
      </div>
    )
  },
  {
    title: 'What can I do with my maps?',
    elements: (
      <div>
        <p className="font-bold">1. Rename</p>
        <br />

        <p className="font-bold">2. Reassign </p>
        <p>- Assign a map to a different RTO number or course</p>
        <p>- Assign a map to be found in a different folder</p>
        <br />

        <p className="font-bold">
          3. Remove <span className="font-normal">(Delete)</span>{' '}
        </p>
        <br />

        <p className="font-bold">
          4. Duplicate <span className="font-normal">(Make a copy)</span>{' '}
        </p>
        <br />

        <p className="font-bold">5. Add notes</p>
        <br />

        <p className="font-bold">6. Download map as a PDF, Word, or Excel file</p>
      </div>
    )
  },
  {
    title: `My unique needs aren't covered in your pricing table, what are my options?`,
    elements: (
      <div className="space-y-6">
        <p>
          We can tailor plans to your enterprise unique needs. Let us know and we'll be in touch shortly:
          support@magicmap.com.au
        </p>
        <p>
          Once processed, you will receive a confirmation from the Magic Support Team normally within 24 business hours.
        </p>
      </div>
    )
  },
  {
    title: 'Can I duplicate a map?',
    elements: (
      <div>
        <p>Indeed you can, in fact– it's easy to do so.</p>
        <br />

        <p>Click on the 3-dot setting icon to see the dropdown menu of map actions.</p>
      </div>
    )
  }
];
