import React from 'react';

export const itemsDownloads = [
  {
    title: 'What file types are my maps available for download in?',
    elements: (
      <div className="space-y-6">
        <p>
          After completing your unit map within Magic Map, you may then download it in any or all of the following
          formats:
        </p>

        <ul className="list-disc list-inside ml-4 space-y-6">
          <li>PDF</li>
          <li>Word</li>
          <li>Excel</li>
        </ul>
      </div>
    )
  },
  {
    title: 'What is in the Unit Pack available for download?',
    elements: (
      <div className="space-y-6">
        <p>
          Downloading a Unit Pack from Magic Map is a quick and easy way to save all of the information about the unit
          you have chosen to sync.
        </p>
        <p>The Unit Pack includes: </p>
        <p>1. The Unit's details from Training.gov.au</p>
        <p>2. The Unit's competency requirements (blank mapping template)</p>
        <p>3. All of your current maps for the selected Unit, if you have created any</p>
      </div>
    )
  }
];
