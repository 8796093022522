import { faqsOptions, options } from '@/assets/data/faqs';
import { PropsChildren } from '@/types/common';
import React, { useMemo } from 'react';

export const DataContext = React.createContext({} as any);

interface Props extends PropsChildren {
  value: any;
}

const DataProvider: React.FC<Props> = ({ children, value }) => {
  const { isReadMore, faqsIndex, faqsSearchResult } = useMemo(() => value?.location?.state || false, [value]);

  const searchResult = useMemo(
    () => (faqsSearchResult ? options.find((x) => x.value === faqsSearchResult) : null),
    [value]
  );

  return (
    <DataContext.Provider
      value={{
        searchResult,
        isReadMore,
        faqsIndex,
        faqsOptions,
        options
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
export { DataProvider };
