import React from 'react';

export const itemsManaging = [
  {
    title: `My unique needs aren't covered in your pricing table, what are my options?`,
    elements: (
      <div className="space-y-6">
        <p>
          We can tailor plans to your enterprise unique needs. Let us know and we'll be in touch shortly:
          support@magicmap.com.au
        </p>
        <p>
          Once processed, you will receive a confirmation from the Magic Support Team normally within 24 business hours.
        </p>
      </div>
    )
  },
  {
    title: `Can I upgrade or downgrade my subscription?`,
    elements: (
      <div className="space-y-6">
        <p>You can change or cancel your plan from your Account Settings.</p>
        <p>
          Upgrades go into effect immediately and you will be billed a prorated amount for the new plan. Prorating means
          we calculate the amount of time you have left in your current billing cycle and use the money you paid in your
          initial plan towards your new plan. This way, your billing cycle does not change and you are paying for the
          new subscription plan of the remaining days of the current cycle.
        </p>

        <p>
          This also works the same way on downgrades, the prorated amount of the remaining time will be added to your
          account as a credit and will be used for the next payment.{' '}
        </p>

        <p>When you cancel your plan, your account will be downgraded immediately to the 7-day trial plan.</p>
      </div>
    )
  }
];
