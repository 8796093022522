import React from 'react';

export const itemsNotifications = [
  {
    title: 'What happens to my maps when Training.gov.au updates a unit?',
    elements: (
      <div>
        <p>
          <i>
            If I update a previously added unit's details after it has been superseded, will the new unit data overwrite
            the old unit data in my previous maps?
          </i>
        </p>
        <br />
        <p>
          <u>Short answer:</u> Your maps stay attached to the outdated release of that unit{' '}
          <span className="font-bold"> unless you choose to update it.</span>
        </p>
        <br />
        <p className="font-bold">Unit releases</p>
        <p>See which release a map is attached to in the unit index. </p>
        <br />
        <p>When there is a new release of a unit, the release number (ie. 1.0, 1.1, etc.) will change.</p>
        <br />
        <p>
          When you continue working on a map that belongs to an outdated release of a unit, a notification bar will
          appear at the top of your screen to ensure you are aware that you are working with superseded data and have
          the option to update.
        </p>
        <br />
        <p>
          Once you have completed editing the map and click Save again, it's your choice to choose to update the map
          (change the release number) or keep it in the same old release.
        </p>
      </div>
    )
  },
  {
    title: 'What notifications will I receive?',
    elements: (
      <div className="space-y-6">
        <p>You will receive notifications via email:</p>
        <p>1. When a unit you have previously mapped has a new release </p>
        <p>2. When a unit you have previously mapped has been superseded</p>
        <p>3. When a course you have previously worked with has been superseded</p>
        <p>4. When someone invites you as a member of their team</p>
        <p>5. With Support/Feedback request confirmation</p>
      </div>
    )
  },
  {
    title: 'Will Magic Map notify me before the next billing cycle begins',
    elements: (
      <div className="space-y-6">
        <p>
          Magic Map will notify you via in-app notifications & email for upcoming invoices{' '}
          <span className="font-bold">3 days before</span> your subscription renews.
        </p>
        <p>
          If you fails to confirm the payment we will retry to send reminders up to <i>4 times within 2 weeks</i>. If
          all retries for a payment fail, your subscription will be switched back to the free trial (if your trial
          expired from before you won't be able to continue using the trial features).
        </p>
      </div>
    )
  }
];
