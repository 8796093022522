import { Link } from 'gatsby';
import React from 'react';

export const itemsPlansBilling = [
  {
    title: 'Can I upgrade or downgrade my subscription?',
    elements: (
      <div className="space-y-6">
        <p>You can change or cancel your plan from your Account Settings.</p>
        <p>
          Upgrades go into effect immediately and you will be billed a prorated amount for the new plan. Prorating means
          we calculate the amount of time you have left in your current billing cycle and use the money you paid in your
          initial plan towards your new plan. This way, your billing cycle does not change and you are paying for the
          new subscription plan of the remaining days of the current cycle.{' '}
        </p>
        <p>
          This also works the same way on downgrades, the prorated amount of the remaining time will be added to your
          account as a credit and will be used for the next payment.{' '}
        </p>
        <p>When you cancel your plan, your account will be downgraded immediately to the 7-day trial plan.</p>
      </div>
    )
  },
  {
    title: 'How much does Magic Map cost?',
    elements: (
      <div>
        <p>
          Our pricing is structured per annum and is dependent on the number of individual units you or your
          organisation is required to create mapping templates for.{' '}
        </p>
        <br />
        <p>
          As you can see from this screenshot from our{' '}
          <Link to="/pricing" className="text-primary">
            pricing page
          </Link>
          , plans start at 50 units ($850pa) and range up to 1000 units ($2,900pa).{' '}
        </p>
        <br />
        <p>After signing up for the appropriate plan you will be billed yearly on the anniversary of your sign up. </p>
        <br />
        <p>
          Having a paid plan means you may create unlimited mapping templates and maps per unit allocated to your
          account and receive continued access to Magic Map's online support, secure data storage, integration
          opportunities, online mapping platform, user collaboration, and more.{' '}
        </p>
        <br />
        <p>
          The 'Users' included within each paid plan refer to the number of collaborators who may be allocated logins.
          For example, the 50 unit plan includes logins for three people to use to collaborate within Magic Map.
        </p>
      </div>
    )
  },
  {
    title: `My unique needs aren't covered in your pricing table, what are my options?`,
    elements: (
      <div className="space-y-6">
        <p>
          We can tailor plans to your enterprise unique needs. Let us know and we'll be in touch shortly:
          support@magicmap.com.au
        </p>
        <p>
          Once processed, you will receive a confirmation from the Magic Support Team normally within 24 business hours.
        </p>
      </div>
    )
  },
  {
    title: 'Will Magic Map notify me before the next billing cycle begins',
    elements: (
      <div className="space-y-6">
        <p>
          Magic Map will notify you via in-app notifications & email for upcoming invoices{' '}
          <span className="font-bold">3 days before</span> your subscription renews.
        </p>
        <p>
          If you fails to confirm the payment we will retry to send reminders up to <i>4 times within 2 weeks</i>. If
          all retries for a payment fail, your subscription will be switched back to the free trial (if your trial
          expired from before you won't be able to continue using the trial features).
        </p>
      </div>
    )
  }
];
